<!--
 * @Version:
 * @Author: wangmengfei
 * @Descripttion:
 * @Date: 2023-04-04 15:40:11
 * @FilePath: \feidream2\src\pages\game\components\threejs\threejs.vue
 * @LastEditors: wangmengfei
 * @LastEditTime: 2023-04-04 17:53:52
-->
<template>
  <div class="wrap" ref="wraps" @mousemove="mouseOver"></div>
</template>
<script>
import * as THREE from 'three'
// import { constructor, update } from './js/romaUtil'
export default {
  name: 'threejs',
  data () {
    return {
      // loader: new THREE.GLTFLoader(),
      scene: null,
      camera: null,
      renderer: null,
      geometry: null,
      material: null,
      cube: null
    }
  },
  methods: {
    create () {
      this.camera = new THREE.PerspectiveCamera(
        75, window.innerWidth / window.innerHeight, 0.1, 1000
      )
      this.camera.position.z = 5
      this.camera.rotation.order = 'YXZ'
      this.scene = new THREE.Scene()
      this.material6 = new THREE.MeshLambertMaterial(require('./img/1994344283.png'))
      this.geometry = new THREE.BoxGeometry(0.5, 0.5, 1.5)
      this.material = new THREE.MeshBasicMaterial({ color: 0xffffff })
      this.cube = new THREE.Mesh(this.geometry, this.material)
      this.cube1 = new THREE.Mesh(this.geometry, this.material)
      this.geometry2 = new THREE.BoxGeometry(0.5, 0.5, 0.5)
      this.material2 = new THREE.MeshBasicMaterial({ color: 0xffffff })
      this.cube2 = new THREE.Mesh(this.geometry2, this.material2)
      this.cube22 = new THREE.Mesh(this.geometry2, this.material2)
      this.geometry3 = new THREE.BoxGeometry(0.5, 5, 0.5)
      this.material3 = new THREE.MeshBasicMaterial({ color: 0xffffff })
      this.cube3 = new THREE.Mesh(this.geometry3, this.material3)
      this.cube33 = new THREE.Mesh(this.geometry3, this.material3)
      this.geometry4 = new THREE.BoxGeometry(1.5, 0.5, 0.5)
      this.material4 = new THREE.MeshBasicMaterial({ color: 0xffffff })
      this.cube4 = new THREE.Mesh(this.geometry4, this.material4)
      this.cube44 = new THREE.Mesh(this.geometry4, this.material4)
      this.box = new THREE.SphereGeometry(0.5)
      this.material5 = new THREE.MeshBasicMaterial({ color: 0xffffff })
      this.cube5 = new THREE.Mesh(this.box, this.material5)
      this.cube55 = new THREE.Mesh(this.box, this.material5)
      this.dbgeometry1 = new THREE.BoxGeometry(50, 1, 50)
      this.dbmaterial1 = new THREE.MeshBasicMaterial({ color: 0x74E45A })
      this.dbcube1 = new THREE.Mesh(this.dbgeometry1, this.dbmaterial1)
      this.dbgeometry2 = new THREE.BoxGeometry(25, 0.5, 18)
      this.dbmaterial2 = new THREE.MeshBasicMaterial({ color: 0x938270 })
      this.dbcube2 = new THREE.Mesh(this.dbgeometry2, this.dbmaterial2)
      this.dbgeometry3 = new THREE.BoxGeometry(0.5, 6, 15)
      this.dbmaterial3 = new THREE.MeshBasicMaterial({ color: 0xB9966F })
      this.dbcube3 = new THREE.Mesh(this.dbgeometry3, this.dbmaterial3)
      this.dbgeometry4 = new THREE.BoxGeometry(0.5, 6, 15)
      this.dbmaterial4 = new THREE.MeshBasicMaterial({ color: 0xB9966F })
      this.dbcube4 = new THREE.Mesh(this.dbgeometry4, this.dbmaterial4)
      this.light = new THREE.AmbientLight(0xadadad)
      this.scene.add(this.light)
      this.camera.target = new THREE.Vector3(0, 0, 0)
      this.camera.position.set(0, 0, 0)
      this.cube.position.set(-5, 1.5, 0)
      this.cube2.position.set(-5, 2, 0)
      this.cube3.position.set(-5, -1, 0)
      this.cube4.position.set(-5, 1.5, 0)
      this.cube1.position.set(5, 1.5, 0)
      this.cube22.position.set(5, 2, 0)
      this.cube33.position.set(5, -1, 0)
      this.cube44.position.set(5, 1.5, 0)
      this.cube5.position.set(-5, 2, 0)
      this.cube55.position.set(5, 2, 0)
      this.dbcube1.position.set(0, -3, 0)
      this.dbcube2.position.set(0, 3.5, -2)
      this.dbcube3.position.set(-7, 0, -2)
      this.dbcube4.position.set(7, 0, -2)
      this.scene.add(this.cube)
      this.scene.add(this.cube2)
      this.scene.add(this.cube3)
      this.scene.add(this.cube4)
      this.scene.add(this.cube1)
      this.scene.add(this.cube22)
      this.scene.add(this.cube33)
      this.scene.add(this.cube44)
      this.scene.add(this.cube5)
      this.scene.add(this.cube55)
      this.scene.add(this.dbcube1)
      this.scene.add(this.dbcube2)
      this.scene.add(this.dbcube3)
      this.scene.add(this.dbcube4)
      this.createDoor()
      this.renderer = new THREE.WebGLRenderer({ antialias: true })
      this.renderer.setSize(window.innerWidth, window.innerHeight)
      this.$refs.wraps.appendChild(this.renderer.domElement)
    },
    // 创建门door
    createDoor () {
      this.doorgeometry1 = new THREE.BoxGeometry(2, 3, 0.3)
      this.doormaterial1 = new THREE.MeshBasicMaterial({ color: 0x5D241E })
      this.doorcube1 = new THREE.Mesh(this.doorgeometry1, this.doormaterial1)
      this.doorcube1.position.set(-1, 0, 2)
      this.scene.add(this.doorcube1)
      this.doorgeometry2 = new THREE.BoxGeometry(2, 3, 0.3)
      this.doormaterial2 = new THREE.MeshBasicMaterial({ color: 0x5D241E })
      this.doorcube2 = new THREE.Mesh(this.doorgeometry2, this.doormaterial2)
      this.doorcube2.position.set(1.005, 0, 2)
      this.scene.add(this.doorcube2)
    },
    animate () {
      requestAnimationFrame(this.animate)
      // this.cube.rotation.x += 0.01
      this.cube.rotation.y += 0.01
      this.cube1.rotation.y += 0.01
      // this.cube2.rotation.x += 0.01
      this.cube2.rotation.y += 0.01
      this.cube22.rotation.y += 0.01
      // this.cube3.rotation.y += 0.5
      // this.cube3.rotation.y += 0.01
      this.cube4.rotation.y += 0.01
      this.cube44.rotation.y += 0.01
      this.dbcube1.rotation.x = 0.3
      this.dbcube2.rotation.x = 0.3
      this.dbcube3.rotation.x = 0.3
      this.dbcube4.rotation.x = 0.3
      // this.dbcube3.rotation.y = 0.1
      // this.cube5.rotation.y += 0.01
      // this.cube3.rotation.z += 0.01
      // this.controls = new THREE.TrackballControls(this.camera)
      // this.controls.target.set(0, 0, 0)
      // this.camera.position.z += 0.01
      this.initControls()
      this.renderer.render(this.scene, this.camera)
    },
    initControls () {
      this.rotateAngle = Math.PI
      this.actualrotateSpeed = (2 / 180) * Math.PI
      this.actualMoveSpeed = 0.1
      document.onkeydown = () => (
        this._onKeyDown()
      )
      document.onkeyup = () => (
        this._onKeyUp()
      )
    },
    _onKeyDown () {
      switch (window.event.keyCode) {
        case 87:
          this.moveForward = true
          this.move()
          break
        case 65:
          this.moveLeft = true
          this.move()
          break
        case 83:
          this.moveBackward = true
          this.move()
          break
        case 68:
          this.moveRight = true
          this.move()
          break
        case 81:
          this.rotateLeft = true
          this.move()
          break
        case 69:
          this.rotateRight = true
          this.move()
          break
        case 90:
          this.rotateUp = true
          this.move()
          break
        case 67:
          this.rotateDown = true
          this.move()
          break
      }
    },
    _onKeyUp () {
      switch (window.event.keyCode) {
        case 87:
          this.moveForward = false
          this.move()
          break
        case 65:
          this.moveLeft = false
          this.move()
          break
        case 83:
          this.moveBackward = false
          this.move()
          break
        case 68:
          this.moveRight = false
          this.move()
          break
        case 81:
          this.rotateLeft = false
          this.move()
          break
        case 69:
          this.rotateRight = false
          this.move()
          break
        case 90:
          this.rotateUp = false
          this.move()
          break
        case 67:
          this.rotateDown = false
          this.move()
          break
      }
    },
    move () {
      if (this.moveForward) this.camera.translateZ(-this.actualMoveSpeed)
      if (this.moveBackward) this.camera.translateZ(this.actualMoveSpeed)
      if (this.moveLeft) this.camera.translateX(-this.actualMoveSpeed)
      if (this.moveRight) this.camera.translateX(this.actualMoveSpeed)
      if (this.rotateLeft) {
        this.rotateAngle += this.actualrotateSpeed
        this.camera.rotateY(this.actualrotateSpeed)
      }
      if (this.rotateRight) {
        this.rotateAngle -= this.actualrotateSpeed
        this.camera.rotateY(-this.actualrotateSpeed)
      }
      if (this.rotateUp) {
        this.camera.translateY(this.actualMoveSpeed)
      }
      if (this.rotateDown) {
        this.camera.translateY(-this.actualMoveSpeed)
      }
    },
    mouseOver (event) {
      if (this.clientX == null) {
        this.clientX = event.clientX
      } else {
        let movex = 0
        movex = event.clientX - this.clientX
        this.camera.rotateY(-movex / 200)
        this.clientX = event.clientX
      }
      if (this.clientY == null) {
        this.clientY = event.clientY
      } else {
        let movey = 0
        movey = event.clientY - this.clientY
        this.camera.rotateX(-movey / 200)
        this.clientY = event.clientY
      }
    }
  },
  mounted () {
    this.create()
    this.animate()
  }
}
</script>
<style lang="stylus">
*
  margin 0
.wrap
  width 100vw
  height 100vh
  overflow hidden
canvas
  width 100% !important
  height 100% !important
</style>
